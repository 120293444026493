import { render, staticRenderFns } from "./basic.vue?vue&type=template&id=79d417d9&scoped=true&"
import script from "./basic.vue?vue&type=script&lang=js&"
export * from "./basic.vue?vue&type=script&lang=js&"
import style0 from "./basic.vue?vue&type=style&index=0&id=79d417d9&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79d417d9",
  null
  
)

/* custom blocks */
import block0 from "./basic.vue?vue&type=custom&index=0&blockType=route"
if (typeof block0 === 'function') block0(component)

export default component.exports